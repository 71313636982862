<template>
  <div class="animated fadeIn">
    <b-nav tabs style="margin-bottom: -1px;">
      <b-nav-item
        :key="index"
        v-for="(item, index) in bankAccounts"
        @click="changeAccount(index, item)"
        :active="selectedTabIndex === index"
        >{{ item }}</b-nav-item
      >
    </b-nav>
    <b-card>
      <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
      <b-row>
        <b-col
          class="mb-3"
          :class="{ 'pl-3': errorRepayments != undefined && errorRepayments.length > 0 }"
        >
          <b-form @submit.stop.prevent="submitSearch" class="pull-left">
            <b-input-group>
              <b-input-group-prepend is-text
                ><i class="fa fa-search"></i
              ></b-input-group-prepend>
              <b-form-input
                v-model="query.search"
                placeholder="Хайх"
              ></b-form-input>
              <b-input-group-append
                ><b-button variant="primary" type="submit"
                  >Хайх</b-button
                ></b-input-group-append
              >
              <b-input-group-append
                ><b-button variant="secondary" @click="reloadData"
                  ><i class="fa fa-refresh"></i
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <div class="pull-right">
            <b-form-select
              v-model="query.per_page"
              :options="[5, 10, 25, 100, 250, 500]"
            >
            </b-form-select>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <div class="table-responsive mb-0">
          <b-table
            id="filter-table"
            stacked="md"
            responsive="sm"
            @sort-changed="sortingChanged"
            :fields="columns"
            :items="errorRepayments"
            :current-page="query.page"
            hover
            bordered
            :small="true"
            :fixed="false"
            caption=""
          >
          <template v-slot:cell(index)="data">
            {{ data.index + pagination.from }}
          </template> 
          <template v-slot:cell(info)="data">
            <div v-if="data.item.phoneNumber !== null && data.item.phoneNumber !== undefined">
              {{ data.item.phoneNumber }}
            <span v-if="data.item.isClose === true" class="badge bg-info ml-2" style="font-size:14px">Хаах</span>
            <span v-if="data.item.isClose === false" class="badge bg-info ml-2" style="font-size:14px">Эргэн төлөх</span>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <div>
              <b-button-group>
                 <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="showUpdateModal(data.item.id, data.item.phoneNumber, data.item.isClose)"
                  v-if="checkPermission('admin.loan-repayments-error-code.update')"
                  class="mr-1"
                  v-b-tooltip.hover
                  title="Мэдээлэл оруулах"
                >
                  <i class="fa fa-info-circle"></i>
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                  :to="{ 
                    name: 'admin.loan-repayments-error-code.edit',
                    params: { id: data.item.id }
                  }"
                  v-if="checkPermission('admin.loan-repayments-error-code.edit')"
                  class="mr-1"
                  v-b-tooltip.hover
                  title="Засах"
                >
                  <i class="fa fa-pencil"></i>
                </b-button>
              </b-button-group>
            </div>
          </template>
          </b-table>
          <div slot="footer" class="mb-0 mt-3">
            <nav
              class="pull-right"
              v-if="pagination.total > query.per_page"
            >
              <b-pagination
                class="mb-0"
                :total-rows="pagination.total"
                :per-page="query.per_page"
                v-model="query.page"
              />
            </nav>
            <p class="mb-0">
              {{ pagination.total }} өгөгдлөөс
              {{ pagination.to ? pagination.from : 0 }} -
              {{ pagination.to }} өгөгдөл харагдаж байна.
            </p>
          </div>
        </div>
      </b-col>
      </b-row>
    </b-card>

      <b-modal ref="update-modal" title="Мэдээлэл оруулах" hide-footer>
          <b-form @submit.stop.prevent="saveUpdateData">
              <b-form-group label="Хэрэглэгчийн утас:" label-for="input-change-phone">
                  <b-form-input maxlength="8" pattern="\d{8}" id="input-change-phone" v-model="selectData.phone_number" required placeholder="Утас"  @keypress="isNumber($event)"></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-4">
                <b-form-checkbox value="true" v-model="selectData.is_close" switch
                  >Хаах эсэх</b-form-checkbox
                >
              </b-form-group>
               <div class="pull-right">
                    <b-button block type="submit" variant="primary">Хадгалах</b-button>
                </div>
          </b-form>
          
      </b-modal>
  </div>
</template>

<script>
export default {
  name: 'LoanRepaymentsErrorCode.list',
  data: function () {
    return {
      isLoading: false,
      columns: [
        { label: '№', key: 'index', class:"text-center" },
        { label: 'Төлсөн огноо', key: 'transactionDate', sortable: true, sort_key:'payment_date'  },
        { label: 'Дүн', key: 'amount', sortable: true, sort_key:'amount', class:"text-right" },
        { label: 'Гүйлгээний утга', key: 'payDescription' },
        { label: 'Төлсөн данс', key: 'payAccountNumber' },
        { label: 'Шилжүүлсэн банк', key: 'transferBankName' },
        { label: 'Шилжүүлсэн данс', key: 'transferAccountNumber' },
        { label: 'Мэдээлэл', key: 'info' },
        { label: 'Үйлдэл', key: 'actions', class:"text-center" }
      ],
      pagination: {
        total: 0, 
        from: 0,
        to: 0
      },
      selectData: {
        id: null,
        phone_number: null,
        is_close: null
      },
      query: {
        page: 1,
        per_page: 10,
        orderDirection: "ASC",
        orderBy: "id",
        search: "",
        account: 460029584,
        columns: "'payment_date', 'amount'"
      },
      filterShow: false,
      selectedTabIndex: 0,
      bankAccounts: [],
      errorRepayments: [],
    }
  },
  created() {
    this.getBankAccounts();
  },
  mounted() {
    this.getErrorRepayments();
    console.log(this)
  },
  watch: {
    query: {
      handler(page) {
        this.getErrorRepayments()
      },
      deep: true
    }
  },
  methods: {
    sortingChanged(ctx) {
      for (let i in this.fields) {
        if (this.fields[i].key == ctx.sortBy) {
          this.$data.query.orderBy = this.fields[i].sort_key;
          break;
        }
      }
      this.$data.query.orderDirection = ctx.sortDesc ? "ASC" : "DESC";
    },
    getErrorRepayments: function() {
      this.$data.isLoading = true
      this.$http
        .get(this.$config.API_URL + `CustomerWebService/get_error_repayments`, {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
          params: this.$data.query
        })
        .then((response) => {
          this.$data.isLoading = false
          this.$data.errorRepayments = response.body.data;
          this.$data.pagination.to = response.body.to;
          this.$data.pagination.total = response.body.total;
          this.$data.pagination.from = response.body.from;
        }, response => {
          this.isLoading = false;
      })
    },
    resetDefaultValues: function() {
      this.$data.query.page = 1;
      this.$data.query.per_page = 10;
      this.$data.query.orderDirection = "ASC";
      this.$data.query.search = ""
    },
    changeAccount(index, account) {
      this.$data.selectedTabIndex = index;
      this.$data.query.account = account;
      this.resetDefaultValues();
      this.getErrorRepayments();
    },
    getBankAccounts: function() {
      this.$http
        .get(this.$config.API_URL + "CustomerWebService/get_company_bank_accounts", {
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then((response) => {
          this.$data.bankAccounts = response.body.data;
        })
    },
    submitSearch: function() {
      this.$data.query.page = 1;
    },
    reloadData: function() {
      this.resetDefaultValues()
      this.getErrorRepayments()
    },
    hideUpdateModal() {
        this.$refs['update-modal'].hide()
    },
    showUpdateModal: function(_id, _phone_number, _is_close) {
      this.$data.selectData = {
        id: _id,
        phone_number: _phone_number,
        is_close: (_is_close != null) ? _is_close : false
      }
      this.$refs['update-modal'].show()
    },
    saveUpdateData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "CustomerWebService/set_error_transaction_update",
          this.$data.selectData,
          { headers: this.$store.getters.httpHeader, emulateJSON: true }
        )
        .then(
          (response) => {
            this.isLoading = false;
            console.log(response)
            if (response.body.responseResultType == "SUCCESS") {
              this.showToast(
                "Амжилттай",
                "Амжилттай хадгалагдлаа",
                "success"
              );
              this.hideUpdateModal()
              this.getErrorRepayments()
            } else {
              this.showToast("Алдаа", "Хадгалах үед алдаа гарлаа", "danger");
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    isNumber: function(evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();;
        } else {
            return true;
        }
    }
  }
}
</script>
